<template>
  <div>
  <TosListVuetify serviceName="yard" entityName="persons" >
    <template #customFilters="slotProps">
      <v-container fluid>
       <v-row>
          <v-col cols="4">
            <v-text-field  :label="tLabel('Surname number')" v-model="slotProps.data.surname" />
          </v-col>
       </v-row>
      </v-container>
    </template>
    <template v-slot:editF_CANC>
       <v-container fluid>
      <v-row>
      </v-row>
       </v-container>
    </template>
  </TosListVuetify>

</div>

</template>


<script>
import TosListVuetify from '../../../../components/anagrafiche/TosListVuetify';
import FormatsMixins from "../../../../mixins/FormatsMixins";

export default {
  name: "Persons",
  data() {
    return {
      categories: null,
      unitTypes: null,
      item:null,
      provaCodice: null,
      provaType: null,
      headers: [
      ],
    };
  },
  service: null,
  methods: {
    // async prepareItem(data){
    //   if(this.categories==null) this.categories=await this.$api.get(this.$apiConfiguration.BASE_PATH+"categories/shortList");
    //   if(this.unitTypes==null) this.unitTypes=[
    //     {text:"Unit", value:"UNT"},
    //     {text:"Bulk", value:"BUL"},
    //     {text:"Truck", value:"TRK"},
    //   ];
    // },
  },
  components: {TosListVuetify},
  mixins: [FormatsMixins],
};
</script>
